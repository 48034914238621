import React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import { borderRadius, padding, styled } from '@mui/system';
import InsightsIcon from '@mui/icons-material/Insights';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StreamIcon from '@mui/icons-material/Stream';
import { ReactComponent as Bi } from '../../Assets/AlertIcon/Bi.svg';
import { ReactComponent as Management } from '../../Assets/AlertIcon/Management.svg';
import { ReactComponent as Analytics } from '../../Assets/AlertIcon/Analytics.svg';
import { useNavigate } from 'react-router-dom';
import { notify } from '../../common/functions/react-toastify.function';



const getBorderStyle = (item) => {
  if (item === 'Business Intelligence') {
    return '1px solid #333';
  }
  return 'none';
};
const getBorderStyleRight = (item) => {
  if (item === 'Business Intelligence') {
    return 'none';
  }
  return '1px solid #333';
};
const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#000', // Solid background color
  color: '#fff',
  padding: theme.spacing(2),
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  zIndex: 1,
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit', // Ensures the gradient follows the border-radius
    padding: '1px', // Thickness of the border
    background: 'linear-gradient(to right, rgba(55, 55, 55, 1), rgba(0, 0, 0, 0.5))',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: -1,
  },
}));

const StyledBoxTwo = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#000', // Solid background color
  color: '#fff',
  padding: theme.spacing(2),
  borderRadius: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  zIndex: 1,
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit', // Ensures the gradient follows the border-radius
    padding: '1px', // Thickness of the border
    background: 'linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(55, 55, 55, 1))',
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: -1,
  },
}));


const iconStyles = { background: '#BD1D2340', width: '44px', height: '44px', color: '#BD1D23', borderRadius: '4px', padding: '4px' }

const iconMapping = {
  'Data Analytics': <Analytics style={iconStyles} />,
  'Business Intelligence': <Bi style={iconStyles} />,
  'User Management': <Management style={iconStyles} />
};

export const SideBox = () => {

  const navigate = useNavigate()
  const permissionsJSON = localStorage.getItem('permissions');
  let permissions = [];
  try {
    permissions = JSON.parse(permissionsJSON) || [];
  } catch (error) {
    console.error("Failed to parse permissions:", error);
  }
  const permissionNames = Array.isArray(permissions) ? permissions.map(permission => permission.name) : [];

  const handleNavigation = (item) => {
    if (item === 'User Management') {
      navigate('/userManagement')
    }
  }
  const handleComingSoon =(name)=>{
    notify('succes', name === 'BI' ? 'Business Intelligence Coming Soon' : 'Data Analytics Coming Soon')
  }
  return (


    <Container sx={{ marginTop: '7%' }}>
      <Typography variant="h5" gutterBottom color={'white'} align="left">
        OkO! see the unseen.
      </Typography>
      <Typography fontSize={'13px'} gutterBottom color={'#cccccc'} align="left">
        Discover the power of precision with our state-of-the-art fiber optic lines monitoring system.
      </Typography>
      <Grid marginTop={2} container spacing={2}>
        {['Data Analytics', 'Business Intelligence', 'User Management', 'Data Analytics']?.filter(category => permissionNames?.includes(category)) // Filter categories based on permissions
          ?.map(item => (
            <Grid item xs={12} key={item} sx={{ cursor: 'pointer' }} onClick={() => handleNavigation(item)}>
              <StyledBox>
                <Box>
                  <Typography variant="h6" align='left' fontWeight={400}>{item === 'User Management' ? 'Access Control' : item}</Typography>
                  <Typography fontSize={'11px'} align='left' color={'#cccccc'}>
                    Manage Control of the resources.
                  </Typography>
                </Box>
                {iconMapping[item]}
              </StyledBox>
            </Grid>
          ))}
        <Grid item xs={12} key={2} sx={{ cursor: 'pointer' }}  onClick={() => handleComingSoon('DA')}>
          <StyledBoxTwo>
            <Box>
              <Typography variant="h6" align='left' fontWeight={400}>Data Analytics</Typography>
              <Typography fontSize={'11px'} align='left' color={'#cccccc'}>
                Analyze data to uncover insights
              </Typography>
            </Box>
            {iconMapping['Data Analytics']}
          </StyledBoxTwo>
        </Grid>
        <Grid item xs={12} key={2} sx={{ cursor: 'pointer' }}  onClick={() => handleComingSoon('BI')}>
          <StyledBox>
            <Box>
              <Typography variant="h6" align='left' fontWeight={400}>Business Intelligence</Typography>
              <Typography fontSize={'11px'} align='left' color={'#cccccc'}>
                Turn data into actionable insights
              </Typography>
            </Box>
            {iconMapping['Business Intelligence']}
          </StyledBox>
        </Grid>
        <Grid item xs={12} key={2} sx={{ cursor: 'pointer' }} >
          <StyledBoxTwo>
            <Box>
              <Typography variant="h6" align='left' fontWeight={400}>Network Monitoring</Typography>
              <Typography fontSize={'11px'} align='left' color={'#cccccc'}>
              Monitor & Track your fiber lines to ensure optimal performance and reliability.
              </Typography>
            </Box>
            {iconMapping['Data Analytics']}
          </StyledBoxTwo>
        </Grid>

      </Grid>
    </Container>
  );
};
