import React, { useState } from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import StreamIcon from '@mui/icons-material/Stream';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import { ReactComponent as FttsIcon } from '../../Assets/AlertIcon/ftts.svg';
import { ReactComponent as Mpbn } from '../../Assets/AlertIcon/MPBN.svg';
import { ReactComponent as Otn } from '../../Assets/AlertIcon/OTN.svg';
import { ReactComponent as IPRAN } from '../../Assets/AlertIcon/IPRAN.svg';



const StyledBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#5b585840',
  justifyContent: 'left',
  alignItems: 'left',
  marginBottom: theme.spacing(1)
}));

const categories = ['FTTS', 'MPBN', 'OTN', 'IPRAN'];


export const SideBoxBottom = () => {
  const navigate = useNavigate()
  const [tab, setTab] = useState();
  const permissionsJSON = localStorage.getItem('permissions');
  let permissions = [];
  try {
    permissions = JSON.parse(permissionsJSON) || [];
  } catch (error) {
    console.error("Failed to parse permissions:", error);
  }
  const permissionNames = Array.isArray(permissions) ? permissions.map(permission => permission.name) : [];

  const handleTabs = (tab) => {
    setTab(tab)
    if (tab === 'FTTS') {
      sessionStorage.setItem('selectedTab', 'ftts');
      navigate('/fibers');
    }
    if (tab === 'MPBN') {
      sessionStorage.setItem('selectedTab', 'mpbn');
      navigate('/fibers')
    }
    if (tab === 'OTN') {
      sessionStorage.setItem('selectedTab', 'otn');
      navigate('/fibers')
    }
    if (tab === 'IPRAN') {
      sessionStorage.setItem('selectedTab', 'ipran');
      navigate('/fibers')
    }
  }
  const iconStyles = { color: 'white', marginTop: '18px' }
  const iconMap = {
    FTTS: <FttsIcon style={iconStyles} />,
    MPBN: <Mpbn style={iconStyles} />,
    OTN: <Otn style={iconStyles} />,
    IPRAN: <IPRAN style={iconStyles} />,
  };
  return (
    <Container sx={{ marginTop: '2%' }}>
      <Grid container spacing={2} justifyContent="left">
        {categories
          ?.filter(category => permissionNames?.includes(category)) // Filter categories based on permissions
          ?.map(category => (
            <Grid item key={category} onClick={() => handleTabs(category)} sx={{ cursor: 'pointer' }}>
              <StyledBox>
                {iconMap[category]}
              </StyledBox>
              <Typography variant="body2" color="white" align="center" fontSize={'12px'} fontWeight={600}>
                {category}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};